<template>
    <div class="mt-5">
        <loading :loading="loading" spinner-class="justify-center">
            <b-table striped :items="items" :fields="fields" class="stripped-table">
                <template #cell(fullname)="data">
                    <div class="flex items-center">
                        <div class="mr-2">
                            <a href="#">
                                <img :src="data.item.images !== '' && data.item.images !== null ? data.item.images : require('@/assets/images/avatars/avatar-1.jpg')" class="bg-gray-200 border border-white rounded-full w-10 h-10">
                            </a>
                        </div>
                        <div>
                            <h5 class="font-bold color-dark">{{ data.item.fullname }}</h5>
                            <span>{{ data.item.no_induk }}</span>
                        </div>
                    </div>
                </template>
                <template #cell(date)="data">
                    <div>
                        {{ data.item.skripsi ? formatDate(data.item.skripsi.created_at) : '' }}
                    </div>
                </template>
                <template #cell(status)="data">
                    <div>
                        <span v-if="data.item.skripsi.progress == 100" class="badge badge-success">Layak Skripsi</span>
                        <span v-else class="badge badge-warning">Bimbingan</span>
                    </div>
                </template>
                <template #cell(progress)="data">
                    <div>
                        <progress-bar :value="data.item.skripsi.progress" />
                    </div>
                </template>
                <template #cell(action)="data">
                    <div>
                        <router-link :to="{ name: 'DetailMahasiswaBimbingan', params: { id: data.item.skripsi.id } }" class="btn btn-primary">Lihat Detail</router-link>
                    </div>
                </template>
            </b-table>
        </loading>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    // import InputSliderProgress from '@/components/InputSliderProgress.vue'
    import ProgressBar from '@/components/ProgressBar.vue'

    export default {
        name: 'MahasiswaBimbingan',
        components: { ProgressBar },
        computed: {
            ...mapState('skripsi', { data: 'mahasiswa.mahasiswaBimbingan', loading: 'loading' })
        },
        methods: {
            ...mapActions('skripsi', { fetchMahasiswaBimbingan: 'fetchMahasiswaBimbingan', updateSkripsi: 'updateSkripsi' })
        },
        data () {
            return {
                items: [],
                fields: [
                    {
                        key: 'fullname',
                        label: 'Nama Mahasiswa',
                        tdClass: 'align-middle'
                    },
                    {
                        key: 'date',
                        label: 'Tanggal Pengajuan',
                        tdClass: 'align-middle'
                    },
                    {
                        key: 'status',
                        label: 'Status',
                        tdClass: 'align-middle'
                    },
                    {
                        key: 'progress',
                        label: 'Proses Skripsi',
                        tdClass: 'align-middle'
                    },
                    {
                        key: 'action',
                        label: 'Action',
                        tdClass: 'align-middle'
                    }
                ]
            }
        },
        mounted () {
            this.fetchMahasiswaBimbingan()
                .then(res => {
                    this.items = res
                })
                .catch(e => console.log(e))
        }
    }
</script>
