<template>
    <div class="progress-bar">
        <span class="progress-value" :style="{ width: value + '%' }"></span>
    </div>
</template>

<script>
    export default {
        props: {
            value: {
                type: Number,
                default: 0
            },
            max: {
                type: Number,
                default: 100
            }
        }
    }
</script>
